<template>
  <div class="modify-user-card">
    <van-sticky>
      <van-nav-bar left-arrow safe-area-inset-top @click-left="handleReturn">
        <template #title>
          <div class="head-title">编辑我的名片</div>
        </template>
        <template #left>
          <van-icon name="arrow-left" color="#161719" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <main>
      <van-form input-align="right" ref="webVerification">
        <van-field name="uploader" label="头像">
          <template #input>
            <avatarUpload
              :busiId="cardId"
              busiCat="user"
              groupId="cardHeadPic"
            />
          </template>
        </van-field>
        <van-field v-model="cardInfo.name" label="姓名" placeholder="姓名" />
        <van-field
          v-model="cardInfo.contactNum"
          label="联系电话"
          placeholder="联系电话"
          type="number"
        />
        <van-field
          v-model="cardInfo.wxId"
          label="微信号"
          placeholder="微信号"
        />
        <van-field
          v-model="cardInfo.merchantName"
          label="公司"
          placeholder="公司"
        />
        <van-field
          v-model="cardInfo.position"
          label="职位"
          placeholder="职位"
        />
        <div class="input-title">
          <span>自我介绍</span>
          <video-upload
            v-if="videoList.length == 0"
            style="display: inline; float: right"
            :busiId="cardId"
            busiCat="user"
            groupId="descVideo"
            @uploading="handleVideoUploading"
            @load-uploaded="getUserVideo"
            @upload-success="handleVideoUploadSuccess"
            @load="handleVideoLoaded"
          />
        </div>
        <div class="video-box">
          <div
            v-for="item in videoList"
            :key="item.fileId"
            class="video-item-box"
          >
            <video
              :src="item.url"
              :poster="require('../../../assets/userCard.png')"
            ></video>
            <icon-svg
              class="delte"
              iconClass="icontrash-2"
              @click="handleDeleteVideo(item)"
            />
          </div>
        </div>
        <van-field
          v-model="cardInfo.selfIntro"
          type="textarea"
          input-align="left"
          placeholder="自我介绍"
        />
        <div class="input-title"><span>印象标签</span></div>
        <div class="add-tag">
          <van-button
            size="small"
            type="primary"
            plain
            @click="handleInitAddTag"
          >
            <template #default>
              <div class="add-tag-btn">
                添加标签
                <div class="tag-box">
                  <van-icon name="plus" style="color: white" />
                </div>
              </div>
            </template>
          </van-button>
        </div>
        <div class="padding-box">
          <span
            class="tags-container"
            v-for="(item, index) in impressionLabel"
            :key="index"
          >
            <van-tag
              type="primary"
              size="large"
              closeable
              @close="handleRemoveTag(item)"
              >{{ item }}</van-tag
            >
          </span>
        </div>

        <div class="choice-web">
          <div>公司官网</div>
          <div>
            <div class="select-comin">
              <div
                v-for="item in isWebsite"
                :class="['selectBox', websiteId === item.id ? 'bgColor' : '']"
                :key="item.id"
                @click="handleChoiceWeb(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <van-field
          v-if="websiteId === 1"
          v-model="cardInfo.officialWebsite"
          label="自定义"
          placeholder="请输入官网链接"
          clearable
          :rules="[
            {
              required: true,
              pattern: /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/,
              message: '官网地址不正确',
            },
          ]"
        />
        <div class="input-title">
          <span>我的推荐</span>
        </div>
        <!-- <case-item
          v-for="item in cardInfo.myCaseList"
          :key="item.caseId"
          :caseInfo="item"
          showDel
          @deletCase="handleDeleteCase"
        /> -->
        <recommend-Item 
        showDel
        v-for="item in myRecommendList"
       :key="item.id"
       :recommendInfo="item"
       @deletItem="handleDeleteItem">

      </recommend-Item>
      <div class="more-recomond" @click="loadMoreRecommend">更多推荐 <icon-svg  iconClass="iconchevron-down" /></div>
        <div class="input-title">
          <span>我的照片</span>
        </div>
        <div class="input-box">
          <muti-image-upload
            :busiId="cardId"
            busiCat="user"
            groupId="userPics"
          />
        </div>

        <div class="bottom-btns">
          <van-button style="width: 50%" @click="handleReturn">取消</van-button>
          <van-button style="width: 50%" @click="handleSaveModifyUserCard"
            >确定</van-button
          >
        </div>
      </van-form>
    </main>
    <van-dialog
      v-model:show="showAddTag"
      title="请输入标签值"
      show-cancel-button
      confirm-button-color="#333333"
      cancel-button-color="#999999"
      @confirm="handleAddTag"
      @cancel="handleCancelAddTag"
    >
      <van-field
        size="large"
        v-model="tagItemName"
        placeholder="标签"
        center
        clearable
        input-align="center"
      />
    </van-dialog>
  </div>
</template>

<script>
import avatarUpload from "@/components/FileUpload/avatarUpload";
// import caseItem from "@/components/caseItem";
import videoUpload from "@/components/FileUpload/video-upload";
import mutiImageUpload from "@/components/FileUpload/muti-image-upload";
import recommendItem from "../../../components/recommendItem"
import { useRoute, useRouter } from "vue-router";
import {
  getUserCard,
  saveUserCard,
  getRecommend,
  deletedRecommend
} from "@/api/user/userCard";
import { reactive, toRefs, ref } from "vue";
import { Dialog, Toast } from "vant";
import { deleteFile } from "@/api/file";
export default {
  components: {
    avatarUpload,
    // caseItem,
    recommendItem,
    videoUpload,
    mutiImageUpload,
    "van-dialog": Dialog.Component,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cardId = route.params.id;
    const webVerification = ref(null);
    const state = reactive({
      myRecommendList:[],
      userId:'',
      current:1,
      websiteId: null,
      isWebsite: [
        { id: 0, title: "躺赢官网" },
        { id: 1, title: "自定义" },
      ],
      showAddTag: false,
      videoList: [],
      tagItemName: "",
      playOrPause: false,
      iconPlayShow: true,
      cardInfo: {
        cardId: "",
        contactNum: "",
        name: "",
        wxId: "",
        selfIntro: "",
        impressionLabel: "",
        officialWebsite: "",
        myCaseList: [],
        position: "",
        merchantName: "",
        isSetWebsite: null,
        isInitWebsite: "",
      },
      impressionLabel: [],
    });
    //选择官网或自定义官网
    const handleChoiceWeb = (id) => {
      if (id === 0 && state.cardInfo.isInitWebsite !== 1) {
        Toast.fail("你还未在web端设置微官网");
      } else {
        state.websiteId = id;
        state.cardInfo.isSetWebsite = id;
      }
    };
    const getUserVideo = (fileList) => {
      state.videoList = fileList;
    };
    const handleVideoUploadSuccess = (flie) => {
      Toast.loading({ message: "视频上传成功" });
      state.videoList.push(flie);
      Toast.clear();
    };
    const handleVideoUploading = () => {
      Toast.loading({
        forbidClick: true,
        message: "视频上传中",
        duration: 8000,
      });
    };
    const handleVideoLoaded = () => {
      Toast.clear();
    };
    const handleDeleteVideo = (file) => {
      if (file.fileId) {
        deleteFile({ fileId: file.fileId }).then((data) => {
          if (data) {
            state.videoList = state.videoList.filter(
              (item) => item.fileId !== file.fileId
            );
          }
        });
      }
    };
    const loadUserCardInfo = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中",
        });
        let data = await getUserCard();
        state.cardInfo = data;
        state.websiteId = data.isSetWebsite;
        state.cardInfo.name = data.createId?data.name:data.defaultName
        state.cardInfo.contactNum = data.createId?data.contactNum:data.defaultContactNum
        state.cardInfo.selfIntro = data.createId?data.selfIntro:"我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！"
        state.cardInfo.impressionLabel = data.createId?data.impressionLabel:"专业,热情"
        state.userId = data.createId
        let allRecommend = await getRecommend({loginUserId:data.createId})
        state.myRecommendList = allRecommend.records
        if (data.impressionLabel) {
          state.impressionLabel = data.impressionLabel.split(",");
        } else {
          state.impressionLabel = [];
        }
        Toast.clear();
      } catch (error) {
        console.log(error);
        Toast.clear();
      }
    };

    loadUserCardInfo();
    const loadMoreRecommend = async()=>{
      state.current =  state.current+1
      let itemList = await getRecommend({loginUserId:state.userId,current:state.current})
      state.myRecommendList.push(...itemList.records)
    }
    const handleReturn = () => {
      router.back();
    };
    const handleSaveModifyUserCard = async () => {
      let param = {
        ...state.cardInfo,
        impressionLabel: state.impressionLabel.toString(),
      };
      if (state.websiteId === 1) {
        webVerification.value.validate().then(() => {
          saveUserCard(param).then((res) => {
            if (res) {
              router.back();
            }
          });
        });
      } else {
        let res = await saveUserCard(param);
        if (res) {
          router.back();
        }
      }
    };
    const handleInitAddTag = () => {
      state.showAddTag = true;
    };
    const handleDeleteItem = async (recommendInfo) => {
      let res = await Dialog.alert({
        title: "删除",
        message: "是否删除此推荐",
        showCancelButton: true,
      });
      if (res) {
        let res = await deletedRecommend({recommendId:recommendInfo.recommendId,contentType:recommendInfo.contentType})
        if (res) {
          state.myRecommendList.forEach((item,index,array)=>{
          if(item.recommendId === recommendInfo.recommendId){
            console.log("要清除的推荐",item,array);
            array.splice(index,1)
           }
          })
          let allRecommend = await getRecommend({loginUserId: state.userId,size:state.current*3})
          state.myRecommendList = allRecommend.records
          Toast.success("删除成功");
        }
      }
    };
    // const handleDeleteCase = async (caseId) => {
    //   console.log(caseId);
    //   let res = await Dialog.alert({
    //     title: "删除",
    //     message: "是否删除此案例",
    //     showCancelButton: true,
    //   });
    //   if (res) {
    //     if (await deleteCardCaseApp(caseId)) {
    //       Toast.success("删除成功");
    //       loadUserCardInfo();
    //     }
    //   }
    // };
    const handleAddTag = () => {
      if (state.tagItemName) {
        state.impressionLabel.push(state.tagItemName);
        state.tagItemName = "";
      }
    };
    const handleRemoveTag = (item) => {
      if (item) {
        state.impressionLabel = state.impressionLabel.filter(
          (tag) => tag !== item
        );
      }
    };
    const handleCancelAddTag = () => {
      state.showAddTag = false;
    };
    return {
      ...toRefs(state),
      loadMoreRecommend,
      handleChoiceWeb,
      cardId,
      handleReturn,
      handleInitAddTag,
      handleAddTag,
      handleCancelAddTag,
      handleSaveModifyUserCard,
      // handleDeleteCase,
      handleDeleteItem,
      handleRemoveTag,
      getUserVideo,
      handleVideoUploadSuccess,
      handleDeleteVideo,
      handleVideoUploading,
      handleVideoLoaded,
      webVerification,
    };
  },
};
</script>
<style lang="scss">
.modify-user-card {
  .van-field__label {
    font-weight: 600;
    color: #333333;
  }
}
</style>
<style lang="scss" scoped>
.modify-user-card {
  text-align: left;
  margin-bottom: 50px;
  .padding-box {
    padding: 16px;
    span {
      margin-right: 5px;
    }
  }
  .choice-web {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    > div:nth-child(1) {
      font-size: 14px;
      font-weight: 600;
    }
    > div:nth-child(2) {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      .select-comin {
        .selectBox {
          padding: 0 10px;
          float: left;
          color: #999999;
          border: 1px solid #999999;
          line-height: 22px;
          margin-left: 10px;
          border-radius: 4px;
          box-sizing: border-box;
        }
        .bgColor {
          color: #fff;
          background-color: #289fff;
          border: none;
          border: 1px solid #289fff;
        }
      }
    }
  }
  .head-title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
  }
  main {
    position: relative;
    .input-box {
      padding: 0px 10px;
    }
    .more-recomond{
      height: 35px;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      background-color: #E5E5E5;
      color: #9999;
      border-color: none;
    }
    .input-title {
      font-weight: 600;
      text-align: left;
      span {
        font-size: 13px;
        box-sizing: border-box;
        width: 100%;
        padding: 0.26667rem 0.42667rem;
        overflow: hidden;
        color: #323233;
        line-height: 0.64rem;
        background-color: #fff;
      }
    }
    .video-box {
      .video-item-box {
        position: relative;
        .delte {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -20px;
          margin-left: -20px;
          width: 18px;
          height: 20px;
          background: #ffffff;
          opacity: 0.8;
          border-radius: 50%;
          color: red;
          padding: 10px;
        }
        padding: 16px;
        video {
          width: 100%;
          height: 200px;
        }
      }
    }
    .add-tag {
      padding: 0px 16px;
      .add-tag-btn {
        font-size: 12px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        .tag-box {
          margin-left: 3px;
          display: flex;
          align-items: center;
          background: #289fff;
          border-radius: 50%;
          justify-content: center;
        }
      }
    }
    .bottom-btns {
      width: 100%;
      position: fixed;
      bottom: 0px;
      button {
        height: 50px;
        color: #fff;
        box-sizing: border-box;
      }
      button:nth-child(1) {
        background-color: #bfbfbf;
      }
      button:nth-child(2) {
        background-color: #ff6e01;
      }
    }
  }
}
</style>
