<template>
  <div class="muti-upload">
    <van-uploader
      v-model="fileList"
      :after-read="afterRead"
      :before-read="beforeRead"
      :before-delete="beforeDelete"
      multiple
      accept="image/png,image/jpg, image/jpeg, image/gif"
    >
    </van-uploader>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { uploadFile, searchFileList, deleteFile } from "@/api/file/index";
export default {
  props: {
    busiId: {
      type: [Number, String],
    },
    busiCat: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      defalut: "",
    },
  },
  setup(props) {
    const state = reactive({
      fileList: [],
      currentFileId: 0,
      fileId: "",
      file: null,
    });
    const afterRead = (files) => {
      if (files.length) {
        files.map(async (fileItem) => {
          state.file = fileItem.file;
          fileItem.status = "uploading";
          fileItem.message = "上传中...";
          let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", fileItem.file); //接口需要传的参数
          formData.append("busiId", props.busiId); //接口需要传的参数
          formData.append("busiCat", props.busiCat); //接口需要传的参数
          formData.append("groupId", props.groupId); //接口需要传的参数
          let data = await uploadFile(formData);
          if (data) {
            let item = {
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
              fileId: data.fileId,
            };
            console.log(item);
            state.fileList.splice(state.fileList.indexOf(fileItem),1);
            state.fileList.push(item);
          }
          console.log(state.fileList);
        });
      } else {
        state.file = files.file;
        files.status = "uploading";
        files.message = "上传中...";
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", files.file); //接口需要传的参数
        formData.append("busiId", props.busiId); //接口需要传的参数
        formData.append("busiCat", props.busiCat); //接口需要传的参数
        formData.append("groupId", props.groupId); //接口需要传的参数
        uploadFile(formData)
          .then((data) => {
            let item = {
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
              fileId: data.fileId,
            };
            state.fileList.pop();
            state.fileList.push(item);
            console.log(state.fileList);
          })
          .catch((err) => {
            console.log(err);
            state.fileList.pop();
            files.status = "failed";
            files.message = "上传失败";
          });
      }
    };
    const uploadFileToServer = (busiId) => {
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      if (props.busiId !== undefined) {
        formData.append("busiId", props.busiId); //接口需要传的参数
      } else {
        formData.append("busiId", busiId); //接口需要传的参数
      }
      formData.append("file", state.file); //接口需要传的参数
      formData.append("busiCat", props.busiCat); //接口需要传的参数
      formData.append("groupId", props.groupId); //接口需要传的参数
      uploadFile(formData);
    };
    const beforeRead = () => {
      return true;
    };
    const beforeDelete = (file) => {
      if (file.fileId) {
        deleteFile({ fileId: file.fileId }).then((data) => {
          if (data) {
            state.fileList = state.fileList.filter(
              (item) => item.fileId !== file.fileId
            );
          }
        });
      }
    };
    const searchFile = () => {
      let param = {
        busiId: props.busiId,
        busiCat: props.busiCat,
        groupId: props.groupId,
      };
      searchFileList(param).then((data) => {
        if (data) {
          data.map((bloc) => {
            let item = {
              fileId: bloc.fileId,
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${bloc.url}`,
            };
            state.fileList.push(item);
          });
        }
      });
    };
    onMounted(() => {
      searchFile();
    });
    return {
      ...toRefs(state),
      afterRead,
      beforeRead,
      beforeDelete,
      uploadFileToServer,
    };
  },
};
</script>

<style lang="scss" scoped></style>
