<template>
  <div>
    <van-uploader
      :after-read="afterRead"
      :before-read="beforeRead"
      :before-delete="beforeDelete"
      accept="video/*"
      :max-count="1"
    >
      <template #default>
        <van-button
          type="primary"
          plain
          size="small"
          class="video-upload-btn"
          >上传视频</van-button
        >
      </template>
    </van-uploader>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { searchFileList, deleteFile, uploadFile } from "@/api/file/index";
import { Toast } from 'vant';
export default {
  props: {
    busiId: {
      type: [Number, String],
    },
    busiCat: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      defalut: "",
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      fileList: [],
      currentFileId: 0,
      fileId: "",
      file: null,
    });
    const beforeRead = () => {
      return true;
    };
    const beforeDelete = (file) => {
      if (file.fileId) {
        deleteFile({ fileId: file.fileId }).then((data) => {
          if (data) {
            state.fileList = state.fileList.filter(
              (item) => item.fileId !== file.fileId
            );
          }
        });
        console.log(file.fileId);
      }
    };
    const afterRead = (file) => {
      state.file = file.file;
      file.status = "uploading";
      file.message = "上传中...";
      emit("uploading")
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file); //接口需要传的参数
      formData.append("busiId", props.busiId); //接口需要传的参数
      formData.append("busiCat", props.busiCat); //接口需要传的参数
      formData.append("groupId", props.groupId); //接口需要传的参数
      uploadFile(formData)
        .then((data) => {
          let item = {
            url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
            fileId: data.fileId,
          };
          state.fileList.pop();
          state.fileList.push(item);
          console.log(state.fileList);
          emit("upload-success", item);
        })
        .catch((err) => {
          file.status = "failed";
          file.message = "上传失败";
          state.fileList.pop();
          Toast.fail('视频过大');
          console.log(err);
        });
    };
    const searchFile = () => {
      let param = {
        busiId: props.busiId,
        busiCat: props.busiCat,
        groupId: props.groupId,
      };
      searchFileList(param).then((data) => {
        if (data) {
          let res = data.map((bloc) => {
            let item = {
              fileId: bloc.fileId,
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${bloc.url}`,
            };
            state.fileList.push(item);
            return item;
          });
          console.log("===========res", res);
          console.log("========", props);
          emit("load-uploaded", res);
        }
      });
    };
    searchFile();
    return {
      ...toRefs(state),
      afterRead,
      beforeDelete,
      beforeRead,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-upload-btn {
    width: 70px;
    margin-right:16px;
    border-radius: 4px;
}
</style>
